var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(!_vm.can_show_form)?_c('v-skeleton-loader',{class:_vm.$style.loader,attrs:{"type":"image"}}):_c('v-card',{staticClass:"mr-auto ml-auto",attrs:{"max-width":"432"}},[_c('v-form',{ref:"form",staticClass:"pa-5",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}},model:{value:(_vm.form_valid),callback:function ($$v) {_vm.form_valid=$$v},expression:"form_valid"}},[_c('v-card-title',{staticClass:"mb-5 flex-column"},[_c('LogoWhite',{staticClass:"mb-3"}),_c('h2',{staticClass:"text-h2"},[_vm._v(" Регистрация ")])],1),(_vm.notify_msg)?_c('div',{class:[_vm.$style.error, 'text-body-1']},[_vm._v(" "+_vm._s(_vm.notify_msg)+" ")]):_vm._e(),_c('v-card-text',{staticClass:"pa-2"},[_c('TextField',{attrs:{"rules":[
            function (v) { return _vm.is_require(v) || _vm.require_message; }
          ],"label":"Фамилия","require":""},model:{value:(_vm.new_user.surname),callback:function ($$v) {_vm.$set(_vm.new_user, "surname", $$v)},expression:"new_user.surname"}}),_c('TextField',{attrs:{"rules":[
            function (v) { return _vm.is_require(v) || _vm.require_message; }
          ],"label":"Имя","require":""},model:{value:(_vm.new_user.name),callback:function ($$v) {_vm.$set(_vm.new_user, "name", $$v)},expression:"new_user.name"}}),_c('TextField',{attrs:{"label":"Отчество"},model:{value:(_vm.new_user.patronymic),callback:function ($$v) {_vm.$set(_vm.new_user, "patronymic", $$v)},expression:"new_user.patronymic"}}),_c('TextField',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.phone_mask),expression:"phone_mask"}],attrs:{"rules":[
            function (v) { return _vm.is_require(v) || _vm.require_message; },
            function (v) { return _vm.is_correct_phone(v) || _vm.invalid_phone_number; }
          ],"label":"Номер телефона","require":"","type":"tel","error":!!_vm.notify_msg,"error-messages":_vm.notify_msg},model:{value:(_vm.new_user.telephone),callback:function ($$v) {_vm.$set(_vm.new_user, "telephone", $$v)},expression:"new_user.telephone"}}),_c('TextField',{attrs:{"rules":[
            function (v) { return _vm.is_require(v) || _vm.require_message; },
            function (v) { return _vm.is_valid_pass(v) || _vm.invalid_password_format; }
          ],"label":"Пароль","type":"password","require":""},model:{value:(_vm.new_user.password),callback:function ($$v) {_vm.$set(_vm.new_user, "password", $$v)},expression:"new_user.password"}}),_c('TextField',{attrs:{"rules":[
            function (v) { return _vm.is_require(v) || _vm.require_message; },
            function (v) { return _vm.is_equals(v, _vm.new_user.password) || _vm.invalid_password_matching; }
          ],"label":"Повторите пароль","type":"password","require":""},model:{value:(_vm.retry_pass),callback:function ($$v) {_vm.retry_pass=$$v},expression:"retry_pass"}}),_c('Checkbox',{staticClass:"mt-0",attrs:{"input-value":_vm.user_agreements_selected,"rules":[
            function (v) { return _vm.user_agreements_selected || _vm.require_message; }
          ],"require":""},on:{"change":function($event){_vm.user_agreements_selected = $event}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{class:_vm.$style.labelText},[_vm._v(" Нажимая на кнопку, вы даёте согласие на "),_c('a',{class:_vm.$style.link,attrs:{"href":"//www.atol.ru/agreement.html","target":"_blank"},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(" обработку персональных данных ")])])]},proxy:true}])})],1),_c('v-card-actions',{staticClass:"flex-column"},[_c('v-btn',{staticClass:"mb-5",attrs:{"color":"accent darken-4","type":"submit","depressed":"","width":"100%","disabled":!_vm.form_valid}},[_vm._v(" Зарегистрироваться ")]),_c('v-btn',{staticClass:"ml-0",attrs:{"color":"accent darken-4","depressed":"","width":"100%","to":_vm.go_to_signin}},[_vm._v(" Авторизация ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }